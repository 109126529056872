<template>
  <div class="shopList" @click.stop="num = 0">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/shop' }">门店管理</el-breadcrumb-item>
      <el-breadcrumb-item>商家列表</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="shop-screen">
      <el-form
        ref="form"
        :model="shopScreenForm"
        size="mini"
        class="shopScreenForm"
      >
        <el-form-item label="门店名称" label-width="100px">
          <el-input
            v-model="shopScreenForm.shopName"
            style="width: 200px"
          ></el-input>
        </el-form-item>
        <el-form-item label="门店账号" label-width="100px">
          <el-input
            v-model="shopScreenForm.shopAcc"
            style="width: 200px"
          ></el-input>
        </el-form-item>
        <el-form-item label="提前结束售卖时间配置" prop="early_end_time" label-width="180px">
          <el-select
            v-model="shopScreenForm.early_end_time"
            placeholder="请选择"
            style="width:200px;"
          >
            <el-option
              v-for="(item, index) in this.l_cate"
              :key="index"
              :label="item.title"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            style="background: #cd0550; border: none;margin-left:30px;"
            @click.stop="search"
            >搜索</el-button
          >
          <el-button @click.stop.stop="emptySearch">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="data-table">
      <el-table
        :data="tableData"
        stripe
        style="width: 100%"
        type="index"
        :header-cell-style="{ background: '#CD0550', color: '#fff' }"
      >
        <el-table-column prop="id" label="ID"> </el-table-column>
        <el-table-column prop="name" label="门店名称"> </el-table-column>
        <el-table-column prop="account" label="门店账号"> </el-table-column>
        <el-table-column prop="total_turnover" label="总金额"></el-table-column>
        <el-table-column prop="unsettled_amount" label="未结算金额"></el-table-column>
        <el-table-column prop="settled_amount" label="已结算金额"></el-table-column>
        <el-table-column prop="page_views" label="访问量"></el-table-column>
        <el-table-column prop="score" label="评分"> </el-table-column>
        <el-table-column prop="early_end_time" label="提前结束售卖时间"> </el-table-column>
        <el-table-column prop="status_str" label="状态"> </el-table-column>
        <el-table-column prop="created_at" label="创建时间"> </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <div style="position: relative; display: inline">
              <i
                style="color: #3ca6f0"
                @click.stop="operation(scope.row)"
                class="iconfont icon-bianji"
              >
              </i>
              <div v-if="scope.row.num == num" class="operation">
                <p @click.stop="change" :class="active == 1 ? 'active' : ''">
                  编辑
                </p>
                <p @click.stop="seeGoods" :class="active == 2 ? 'active' : ''">
                  查看商品
                </p>
                <p @click.stop="census" :class="active == 3 ? 'active' : ''">
                  统计分析
                </p>
              </div>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="page-num">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="pageSizes"
          :page-size="pageSize"
          layout="prev,pager,next, total,sizes,jumper"
          :total="total"
          background
        >
        </el-pagination>
      </div>
    </div>
    <div class="edit-box" v-if="editShow" @click.stop="editShow = false">
      <div class="edit-content" @click.stop.stop="">
        <div class="close" @click.stop="editShow = false">x</div>
        <el-tabs v-model="activeName">
          <el-tab-pane label="账号设置" name="setAcc">
            <el-form
              :model="changeForm"
              ref="ruleForm"
              :rules="rules"
              label-width="100px"
              class="demo-ruleForm"
            >
              <el-form-item
                label="登录手机号"
                prop="phone"
                class="form-item"
              >
                <el-input
                  placeholder="请输入手机号"
                  v-model="changeForm.phone"
                  maxlength="11"
                  size="small"
                  style="width: 400px"
                ></el-input>
              </el-form-item>
              <el-form-item
                label="登录密码"
                prop="pwd"
                :rules="passRules"
                class="form-item"
              >
                <el-input
                  placeholder="请输入密码"
                  type="password"
                  v-model="changeForm.pwd"
                  size="small"
                  style="width: 400px"
                  auto-complete="new-password"
                ></el-input>
              </el-form-item>
              <el-form-item
                label="确认密码"
                prop="checkPass"
                :rules="passRules"
                class="form-item"
              >
                <el-input
                  placeholder="请再次输入密码"
                  type="password"
                  v-model="changeForm.checkPass"
                  size="small"
                  style="width: 400px"
                  auto-complete="new-password"
                ></el-input>
              </el-form-item>
            </el-form>
            <div class="btns">
              <!-- <el-button size="small">重置</el-button> -->
              <el-button type="primary" size="small" @click.stop="changeOphen"
                >提交</el-button
              >
            </div>
          </el-tab-pane>
          <el-tab-pane label="门店信息" name="shopInfo">
            <el-form
              :model="shopForm"
              ref="ruleForm"
              :rules="rules"
              label-width="180px"
              class="demo-ruleForm"
            >
              <el-form-item
                label="门店名称"
                prop="shopName"
                :rules="[{ required: true, message: '门店名称不能为空' }]"
                class="shopInfo-item"
              >
                <el-input
                  placeholder="请输入门店名称"
                  v-model="shopForm.shopName"
                  size="small"
                  style="width: 460px"
                ></el-input>
              </el-form-item>
              <el-form-item
                label="门店电话"
                prop="phone"
                class="shopInfo-item"
              >
                <el-input
                  placeholder="请输入门店电话"
                  v-model="shopForm.phone"
                  maxlength="11"
                  size="small"
                  style="width: 460px"
                ></el-input>
              </el-form-item>
              <el-form-item
                label="门店介绍"
                prop="shopText"
                :rules="[{ required: true, message: '门店介绍不能为空' }]"
                class="shopInfo-item"
              >
                <el-input
                  :value="shopInfo.introduce"
                  placeholder="请输入门店介绍"
                  type="textarea"
                  v-model="shopForm.shopText"
                  size="small"
                  style="width: 460px"
                  resize="none"
                ></el-input>
              </el-form-item>
              <el-form-item label="门店logo" class="shopInfo-item">
                <div style="width: 460px; display: flex">
                  <el-dialog
                    :visible.sync="dialogVisible"
                    :modal="false"
                    width="40%"
                  >
                    <img width="100%" :src="imgUrl + imageUrl" alt="" />
                  </el-dialog>
                  <el-upload
                    :action="httpUrl + 'api/v1/images'"
                    name="image"
                    class="avatar-uploader"
                    :show-file-list="false"
                    :http-request="uploadURL"
                  >
                    <img
                      v-if="imageUrl"
                      :src="imgUrl + imageUrl"
                      class="avatar"
                    />
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                  </el-upload>
                  <div class="imgTips">
                    <div class="seeImg" @click.stop="bigImg">浏览</div>
                    <p style="margin-top: 10px">
                      <span style="padding-right: 8px">*</span
                      >图片大小：小于100KB
                    </p>
                    <p style="text-indent: 1em">
                      图片尺寸：1：1（推荐尺寸：110px*110px）
                    </p>
                  </div>
                </div>
              </el-form-item>
              <el-form-item label="营业执照" class="shopInfo-item" :rules="[{ required: true, message: '请上传营业执照' }]">
                <div style="width: 460px; display: flex">
                  <el-dialog
                    :visible.sync="dialogVisible1"
                    :modal="false"
                    width="60%"
                  >
                    <img width="100%" :src="imgUrl + business_license_image" alt="" />
                  </el-dialog>
                  <el-upload
                    :action="httpUrl + 'api/v1/images'"
                    name="image"
                    class="avatar-uploader1"
                    :show-file-list="false"
                    :http-request="uploadURL1"
                  >
                    <img
                      v-if="business_license_image"
                      :src="imgUrl + business_license_image"
                      class="avatar1"
                    />
                    <i v-else class="el-icon-plus avatar-uploader-icon1"></i>
                  </el-upload>
                  <div class="imgTips">
                    <div class="seeImg" @click.stop="bigImg1">浏览</div>
                    <p style="margin-top: 10px">
                      <span style="padding-right: 8px">*</span
                      >图片大小：小于100KB
                    </p>
                    <p style="text-indent: 1em">
                      图片尺寸：1：1（推荐尺寸：110px*110px）
                    </p>
                  </div>
                </div>
              </el-form-item>
              <el-form-item
                label="营业执照名称"
                prop="business_license_name"
                class="shopInfo-item"
                :rules="[{ required: true, message: '营业执照名称不能为空' }]"
              >
                <el-input
                  placeholder="请输入门店名称"
                  v-model="shopForm.business_license_name"
                  size="small"
                  style="width: 460px"
                ></el-input>
              </el-form-item>
              <el-form-item
                label="营业执照统一编码"
                prop="business_license_number"
                class="shopInfo-item"
                :rules="[{ required: true, message: '营业执照统一编码不能为空' }]"
              >
                <el-input
                  placeholder="请输入门店名称"
                  v-model="shopForm.business_license_number"
                  size="small"
                  style="width: 460px"
                ></el-input>
              </el-form-item>
              <el-form-item label="食品经营许可证" class="shopInfo-item" :rules="[{ required: true, message: '请上传食品经营许可证' }]">
                <div style="width: 460px; display: flex">
                  <el-dialog
                    :visible.sync="dialogVisible2"
                    :modal="false"
                    width="60%"
                  >
                    <img width="100%" :src="imgUrl + food_business_license" alt="" />
                  </el-dialog>
                  <el-upload
                    :action="httpUrl + 'api/v1/images'"
                    name="image"
                    class="avatar-uploader1"
                    :show-file-list="false"
                    :http-request="uploadURL2"
                  >
                    <img
                      v-if="food_business_license"
                      :src="imgUrl + food_business_license"
                      class="avatar1"
                    />
                    <i v-else class="el-icon-plus avatar-uploader-icon1"></i>
                  </el-upload>
                  <div class="imgTips">
                    <div class="seeImg" @click.stop="bigImg2">浏览</div>
                    <p style="margin-top: 10px">
                      <span style="padding-right: 8px">*</span
                      >图片大小：小于100KB
                    </p>
                    <p style="text-indent: 1em">
                      图片尺寸：1：1（推荐尺寸：110px*110px）
                    </p>
                  </div>
                </div>
              </el-form-item>
              <el-form-item label="提前结束售卖时间配置" prop="early_end_time">
                <el-select
                  v-model="shopForm.early_end_time"
                  placeholder="请选择"
                  style="width:460px;"
                >
                  <el-option
                    v-for="(item, index) in this.l_cate"
                    :key="index"
                    :label="item.title"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item
                label="录入商品开关"
                prop="is_up"
              >
                <el-switch
                  v-model="shopForm.is_up"
                  style="width: 460px"
                  :active-value="1"
                  :inactive-value="0"
                />
               </el-form-item>
              <el-form-item class="shop-info-btn shopInfo-item">
                <!-- <el-button size="small">重置</el-button> -->
                <el-button
                  type="primary"
                  size="small"
                  @click.stop="changeShopInfo"
                  >提交</el-button
                >
              </el-form-item>
            </el-form>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getShopList_api,
  changeShopAcc_api,
  changeShopInfo_api,
  getSlicenseNumber_api
} from "@/api/shop.js"
import { imgUrl, httpUrl } from "@/utils/http.js"
import local from "@/utils/local.js"
//阿里云直传
import {client} from "@/utils/alioss.js"
export default {
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else if (value.search(/^[a-zA-Z0-9]{6,20}$/)) {
        callback(new Error("密码由 6到20位（字母，数字）组成"));
      } else {
        callback();
      }
    };
    var validatePass1 = (rule, value, callback) => {
      let mobile = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/; //最新手机正则
      let tel = /^(0\d{2,3}-){0,1}\d{7,8}$/; //座机
      if (value === "") {
        callback(new Error("请输入手机号"));
      } else if (value.charAt(0) == 0) {
        if (!tel.test(value)) {
          callback(new Error("座机号不正确"))
        }
      } else  if (value.charAt(0) != 0){
        if (!mobile.test(value)) {
          callback(new Error("手机号不正确"))
        }
      } else {
        callback()
      }
    };
    return {
      httpUrl: httpUrl,
      imgUrl: imgUrl,
      shopForm: {
        shopName: "",
        phone: "",
        shopText: "",
        is_up:0,
        business_license_name:'',
        business_license_number:'',
        early_end_time:null
      },
      changeForm: {
        phone: "",
        pwd: "",
        checkPass: "",
      },
      activeName: "setAcc",
      active: 1,
      num: 0,
      shopScreenForm: {
        shopName: "",
        shopAcc: "",
        early_end_time:""
      },
      tableData: [],
      currentPage: 1,
      passRules: [{ required: true, validator: validatePass, trigger: "blur" }],
      rules: {
        phone: [
          { required: true, message: "请输入电话", trigger: "blur" },
          { validator: validatePass1, trigger: 'blur' }
        ],
      },
      imageUrl: "",
      business_license_image:'',
      food_business_license:'',
      editShow: false, //显示编辑弹窗
      pageSizes: [10, 20,50],
      pageSize: 10,
      total: 0,
      shopId: "",
      shopInfo: "",
      tk: "",
      dialogVisible: false,
      dialogVisible1: false,
      dialogVisible2: false,
      shopname: "",
      l_cate: [
        { title: "0分钟", id: 0 },
        { title: "10分钟", id: 10 },
        { title: "20分钟", id: 20 },
      ],
    };
  },
  methods: {
    uploadURL(file){
      let fileName="uploads/chain/image/"+`${Date.parse(new Date())}`+'.png'
      console.log(fileName)
      client().multipartUpload(fileName,file.file).then(res=>{
        this.imageUrl=res.name
      })
    },
    uploadURL1(file){
      let fileName="uploads/chain/image/"+`${Date.parse(new Date())}`+'.png'
      console.log(fileName)
      client().multipartUpload(fileName,file.file).then(res=>{
        this.business_license_image=res.name
         // 去掉url后面的?以及他的参数
          const str = this.removeURLParam(res.res.requestUrls[0],'uploadId')
          const strs = str.substring(0, str.lastIndexOf('?'))
          this.getlicense(strs)
      })
    },
     removeURLParam(url,name){
      var e = eval('/'+name+'=[^&]*&?/g')//参数，例如：“age=18&”，&可有可无，/g表示匹配全局而不是匹配的第一个。
      return url.replace(e, '')
    },
    async getlicense(data) {
      let res = await getSlicenseNumber_api({
        image: data,
      })
      this.shopForm.business_license_name = res.data.data.company_name || ''
      this.shopForm.business_license_number = res.data.data.social_credit_code || ''
     },
    uploadURL2(file){
      let fileName="uploads/chain/image/"+`${Date.parse(new Date())}`+'.png'
      console.log(fileName)
      client().multipartUpload(fileName,file.file).then(res=>{
        this.food_business_license=res.name
      })
    },
    //预览图片
    bigImg() {
      this.dialogVisible = true
    },
    bigImg1() {
      this.dialogVisible1 = true
    },
    bigImg2() {
      this.dialogVisible2 = true
    },
    //改变条数
    handleSizeChange(e) {
      this.pageSize = e
      this.getShopList(this.currentPage, this.pageSize)
    },
    //切换页
    handleCurrentChange(e) {
      this.currentPage = e
      this.getShopList(this.currentPage, this.pageSize)
    },
    //显示操作页面
    operation(row) {
      if (this.num == row.num) {
        this.num = 0
      } else {
        this.num = row.num
      }
      this.shopId = row.id
      this.shopInfo = row
      this.shopForm.phone = row.shop_phone
      this.shopForm.shopText = row.introduce
      this.shopForm.shopName = row.name
      this.imageUrl = row.logo
      this.business_license_image = row.business_license_image
      this.shopForm.business_license_name = row.business_license_name
      this.shopForm.early_end_time = row.early_end_time
      this.shopForm.business_license_number = row.business_license_number
       this.food_business_license = row.food_business_license
      this.shopForm.is_up = row.is_up
      local.set("shopId",row.id)
      local.set("shopName",row.name)
    },
    //编辑商品
    change() {
      this.editShow = true
      this.num = 0
    },
    //查看商品
    seeGoods() {
      this.$router.push("/goods/goodsList")
      local.set("shopName1", this.shopname)
    },
    //统计分析
    census() {
      this.$router.push("/shop/census")
    },
    //获取门店列表
    async getShopList(page, limit, name, account,early_end_time) {
      let res = await getShopList_api({
        page,
        limit,
        name,
        account,
        early_end_time
      })
      this.total = res.data.data.total_count
      this.tableData = res.data.data.list
      this.tableData.forEach((item, index) => {
        item.num = index + 1
        item.early_end_time=item.early_end_time
      })
    },
    //搜索门店
    search() {
      this.currentPage = 1
      this.getShopList(
        this.currentPage,
        this.pageSize,
        this.shopScreenForm.shopName,
        this.shopScreenForm.shopAcc,
        this.shopScreenForm.early_end_time
      )
    },
    //清空搜索数据
    emptySearch() {
      this.shopScreenForm = {}
      this.currentPage = 1
      this.getShopList(this.currentPage, this.pageSize)
    },
    //修改账号
    async changeOphen() {
      if (this.changeForm.pwd == this.changeForm.checkPass) {
        let res = await changeShopAcc_api({
          shop_id: this.shopId,
          account: this.changeForm.phone,
          password: this.changeForm.pwd,
          password_confirmation:this.changeForm.checkPass
        })
        if (res.data.code == 0) {
          this.$message({
            message: "修改成功!",
            type: "success"
          })
          this.editShow = false
          this.changeForm={}
          this.getShopList(
            this.currentPage,
            this.pageSize,
            this.shopScreenForm.shopName,
            this.shopScreenForm.shopAcc,
            this.shopScreenForm.early_end_time
          )
          this.shopForm = {}
        } else {
          this.$message.error(res.data.msg)
        }
      }else{
        this.$message.error("两次输入密码不一致")
      }
    },
    async changeShopInfo() {
      let res = await changeShopInfo_api({
        shop_id: this.shopId,
        name: this.shopForm.shopName,
        shop_phone: this.shopForm.phone,
        introduce: this.shopForm.shopText,
        logo: this.imageUrl,
        is_up:this.shopForm.is_up,
        business_license_image:this.business_license_image,
        business_license_name:this.shopForm.business_license_name,
        early_end_time:this.shopForm.early_end_time,
        business_license_number:this.shopForm.business_license_number,
        food_business_license:this.food_business_license
      })
      if (res.data.code == 0) {
        this.$message({
          message: "修改成功!",
          type: "success"
        })
        this.editShow = false
        this.getShopList(
          this.currentPage,
          this.pageSize,
          this.shopScreenForm.shopName,
          this.shopScreenForm.shopAcc,
          this.shopScreenForm.early_end_time
        )
      }
    }
  },
  created() {
    this.tk = local.get("tk");
    this.getShopList(this.currentPage, this.pageSize);
  }
}
</script>

<style lang="less" scoped>
.shopList {
  width: 100%;
  height: 100%;
  background: #eee;
  box-sizing: border-box;
  padding: 30px;
}
.shop-screen {
  background: #ffffff;
  border-radius: 5px;
  padding: 20px;
  box-sizing: border-box;
  margin-top: 20px;
}
.shopScreenForm {
  display: flex;
  font-size: 14px;
  font-weight: bold;
  color: #666666;
  margin-top: 20px;
}
.data-table {
  margin-top: 20px;
  background: #fff;
  border-radius: 5px;
  overflow: hidden;
}
.page-num {
  display: flex;
  justify-content: flex-end;
  padding: 30px;
}
/deep/.el-pagination.is-background .el-pager li:not(.disabled).active {
  background: #cd0550 !important;
}
/deep/.el-pager li {
  border: 1px solid #bfbfbf;
  background: #fff !important;
  box-sizing: border-box !important;
}
/deep/.el-pagination.is-background .btn-next {
  border: 1px solid #bfbfbf;
  background: #fff !important;
}
/deep/.el-pagination.is-background .btn-prev {
  border: 1px solid #bfbfbf;
  background: #fff !important;
}
.operation {
  cursor: pointer;
  position: absolute;
  top: 0px;
  left: -90px;
  z-index: 99;
  background: #fff;
  p {
    width: 85px;
    height: 40px;
    // background: rgba(246, 219, 229, 0.2);
    border-radius: 0px;
    text-align: center;
    line-height: 40px;
    border: 1px #eee solid;
    font-size: 14px;
    font-weight: bold;
    color: #999999;
  }
}
/deep/.el-table {
  overflow: visible;
}
/deep/td {
  text-align: center !important;
}
/deep/.cell {
  text-align: center !important;
  overflow: visible;
}
/deep/.el-table__body-wrapper {
  overflow: visible;
}
.active {
  background: #cd0550 !important;
  color: #fff !important;
}
/deep/.is-active {
  color: #cd0550;
}
/deep/.el-tabs__active-bar {
  background: #cd0550;
}
.el-table--border::after,
.el-table--group::after,
.el-table::before {
  z-index: 0;
}
.edit-box {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.3);
  font-size: 14px;
  font-weight: bold;
  color: #666666;
}
.edit-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 900px;
  height: 630px;
  overflow-y: auto;
  background: #ffffff;
  padding: 20px;
  box-sizing: border-box;
}
.demo-ruleForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  .form-item {
    margin-top: 20px;
  }
}
.btns {
  margin-top: 200px;
  display: flex;
  justify-content: flex-end;
  padding: 0 30px;
  box-sizing: border-box;
}
.el-button--primary {
  background: #cd0550;
  border: 0;
}
.avatar-uploader {
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  width: 111px;
  height: 111px;
}
.avatar-uploader1 {
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  width: 161px;
  height: 111px;
}
.el-upload {
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 111px;
  height: 111px;
  line-height: 111px;
  text-align: center;
}
.avatar {
  width: 111px;
  height: 111px;
  display: block;
}
.avatar-uploader1 .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon1 {
  font-size: 28px;
  color: #8c939d;
  width: 161px;
  height: 111px;
  line-height: 111px;
  text-align: center;
}
.avatar1 {
  width: 161px;
  height: 111px;
  display: block;
}
.imgTips {
  color: #cd0550;
  font-size: 12px;
  font-weight: bold;
  margin-left: 20px;
  padding-top: 20px;
  p {
    line-height: 26px;
  }
}
.seeImg {
  cursor: pointer;
  width: 70px;
  height: 36px;
  background: #cd0550;
  border-radius: 5px;
  font-size: 16px;
  font-weight: bold;
  color: #ffffff;
  text-align: center;
  line-height: 36px;
}
.shop-info-btn {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}
.shopInfo-item {
  margin-top: 20px;
}
.close {
  position: absolute;
  top: 30px;
  right: 30px;
  font-size: 20px;
  cursor: pointer;
  z-index: 9999;
}
</style>